import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useAppDispatch } from "../../../store/hooks";
import {
  changePasswordRequest,
  editUserProfile,
  getUser,
} from "../../../store/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import GlobalPopup from "../../shared/GlobalPopup/globalPopup";
import { useForm } from "react-hook-form"; // Import react-hook-form

export const MyProfile = () => {
  const dispatch = useAppDispatch();
  const user =
    useSelector((state: any) => state?.auth?.userInfo?.data?.userDetail) || {};
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleClose = () => setShow(false);
  const [userData, setUserData] = useState<any>();
  const [showChangePass, setShowChangePass] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<any>();
  // Function to handle form submit
  const onSubmit = (data: any) => {
    const { firstName, lastName, mobilePhone } = data;
    const formData = { firstName, lastName, mobilePhone };
    dispatch(editUserProfile(formData)).then((result: any) => {
      if (result.payload.isSuccess === true) {
        getProfile();
        toast.success(<>{t("PROFILE_UPDATED_SUCCESSFULLY")}</>);
        setShowEdit(false);
      }
    });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    dispatch(getUser()).then((result: any) => {
      if (result.payload.data) {
        setUserData(result.payload.data.userDetail);
        setValue("firstName", result.payload.data.userDetail.firstName);
        setValue("lastName", result.payload.data.userDetail.lastName);
        setValue("email", result.payload.data.userDetail.email);
        setValue("mobilePhone", result.payload.data.userDetail.mobilePhone);
      }
    });
  };

  const showChangePopup = () => {
    setShow(true);
  };
  const handleSave = async () => {
        try {
          dispatch(changePasswordRequest()).then((result: any) => {
            if (result.payload.isSuccess === true) {
              toast.success("Reset Password request sent via email.");
           }
           console.log(result)
           if (result.payload.isSuccess === false) {
             toast.warning(result.payload.data);
          }
          });
      setShow(false);
      setShowChangePass(false);
    } catch (error) {}
  };

  return (
    <>
      <Container fluid>
        <div className="content_header d-flex justify-content-between align-items-center">
          <div className="header_left">
            <h5 className="content_header_title">{t("MY_PROFILE")}</h5>
          </div>
          <div className="header_right">
            <Button variant="primary" onClick={showChangePopup}>
              <i className="fa-solid fa-user-lock me-1"></i> Reset Password
            </Button>
          </div>
        </div>
        <div className="content_body">
          <div className="profile_wrap">
            <div className="profile_inner">
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <div className="profile_header">
                    <div className="profile_header_left">
                      <div className="profile_header_img">
                        <img src="/assets/images/profile.jpg" alt="" />
                      </div>
                    </div>
                    <div className="profile_header_right">
                      <div className="profile_header_name">
                        {userData?.firstName} {userData?.lastName}
                      </div>
                      <div className="profile_header_email">
                        {userData?.email}
                      </div>
                    </div>
                  </div>

                  <div className="profile_body">
                    {!showEdit && (
                      <div className="form_box">
                        <div className="mb-3">
                          <label className="form-label">First Name</label>
                          <span className="form-control-plaintext">
                            {user.firstName}
                          </span>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Last Name</label>
                          <span className="form-control-plaintext">
                            {user.lastName}
                          </span>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <span className="form-control-plaintext">
                            {user.email}
                          </span>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Mobile Phone</label>
                          <span className="form-control-plaintext">
                            {user.mobilePhone ? user.mobilePhone : "---"}
                          </span>
                        </div>
                      </div>
                    )}
                    {showEdit && (
                      <Form
                        className="form_box"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            {...register("firstName", {
                              required: true,
                              maxLength: 20,
                            })}
                          />
                          {errors.firstName?.type === "required" && (
                            <p className="error-validation">
                              First name is required.
                            </p>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            {...register("lastName", { required: true })}
                          />
                          {errors.lastName && (
                            <p className="error-validation">
                              Last name is required.
                            </p>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder=""
                            readOnly
                            {...register("email")}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="mobilePhone">
                          <Form.Label>Mobile Phone</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            {...register("mobilePhone", {
                              required: true,
                              pattern:
                                /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
                            })}
                          />
                          {errors?.mobilePhone?.type === "pattern" && (
                            <p className="error-validation">
                              Mobile Phone is invalid, please use (123) 123-1234
                              format.
                            </p>
                          )}
                        </Form.Group>

                        <Button type="submit" variant="primary">
                          Save
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => setShowEdit(false)}
                        >
                          Cancel
                        </Button>
                      </Form>
                    )}
                  </div>
                </Col>
                {!showEdit && (
                  <Col xs={12} md={6} lg={4}>
                    <Button variant="primary" onClick={() => setShowEdit(true)}>
                      Edit
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
      </Container>

      {showChangePass && (
        <GlobalPopup
          show={showChangePass}
          showImage={false}
          handleClose={() => setShowChangePass(false)}
          handleSave={handleSave}
          message={
            "Are you sure you want to reset your password? " +
            "An email will be sent to your registered email address to proceed further. Please confirm your action."
          }
        />
      )}

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-30w"
        className="custom_modal"
        centered={true}
      >
        <Modal.Body className="custom_modal_body ">
          <div className="d-flex justify-content-center align-items-center gap-3 flex-column py-2">
            <p className="pt-2 mb-0">
              Are you sure you want to reset your password?
            </p>
            <p className="">
              An email will be sent to your registered email address.
            </p>

            <div>
              <Button variant="primary" onClick={handleSave}>
                Yes
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyProfile;
